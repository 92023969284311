import {
    COLLAPSIBLE_MENU_TOGGLE, TOGGLE_MENU_MODAL, UPDATE_ACCOUNT_MENU
} from './actionConstants';

export const togglCollapseMenuStatus = togglCollapseMenuStatus => ({
    type: COLLAPSIBLE_MENU_TOGGLE,
    payload: togglCollapseMenuStatus
})

export const toggleMenuModal = toggleStatus => ({
    type: TOGGLE_MENU_MODAL,
    payload: {toggleStatus}
});

export const updateAccountMenu = (accountMenuItems)=>({
    type: UPDATE_ACCOUNT_MENU,
    payload: { accountMenuItems }
})